import { Feature, Role } from "./model";

/** Recursive Readonly implementation for any (indexable) [[RootState]] such as
 * an array or object */
type ImmutableIndex<T> = Readonly<{
    [K in keyof T]: Immutable<T[K]>;
}>;
export type Immutable<T> = T extends (...args: any[]) => any ? T : T extends object ? ImmutableIndex<T> : T;

// type ScopeDefinition = Immutable<{
//     title: string;
//     feature?: Feature;
//     requiresRole?: Role;
//     description?: string;
//     children?: Record<string, ScopeDefinition>;
// }>;

// export const ScopeDefinitions = {
//     title: "Permissions",
//     children: {
//         manage: {
//             title: "Verwaltung",
//             children: {
//                 schedule: {
//                     feature: Feature.Roster,
//                     requiresRole: Role.Manager,
//                     title: "Dienstplan",
//                     description:
//                         "Der Mitarbeiter kann für bestimmte Abteilungen Dienstpläne schreiben und veröffentlichen.",
//                     children: {
//                         publish: { title: "Veröffentlichen" },
//                         costs: { title: "Kosten Einsehen" },
//                         note: { title: "Notizen Verwalten" },
//                     },
//                 },
//                 employees: {
//                     title: "Mitarbeiter",
//                     feature: Feature.Employees,
//                     requiresRole: Role.Manager,
//                     children: {
//                         time: { title: "Arbeitszeiten" },
//                         absences: { title: "Abwesenheiten" },
//                         availabilities: { title: "Verfügbarkeiten" },
//                         documents: { title: "Dokumente" },
//                         payroll: { title: "Lohnabrechnung" },
//                         advances: { title: "Vorschüsse" },
//                         balances: { title: "Konten" },
//                         coreData: { title: "Stammdaten" },
//                         contracts: { title: "Verträge" },
//                         permissions: { title: "Zugänge & Rechte" },
//                         positions: { title: "Positionen" },
//                         create: { title: "Mitarbeiter Anlegen" },
//                         delete: { title: "Mitarbeiter Löschen" },
//                         messages: { title: "Nachrichten Versenden" },
//                     },
//                 },
//                 settings: {
//                     requiresRole: Role.Manager,
//                     title: "Einstellungen",
//                     description: "Der Mitarbeiter kann Änderungen an den Grundeinstellungen vornehmen.",
//                     children: {
//                         company: { title: "Unternehmen" },
//                         workAreas: { title: "Arbeitsbereiche" },
//                         time: { title: "Zeiterfassung" },
//                         documents: { title: "Dokumente" },
//                         bonuses: { title: "Zuschläge" },
//                         benefits: { title: "Sonderleistungen" },
//                         accounting: { title: "Buchhaltung" },
//                         billing: { title: "Rechnungsstellung" },
//                     },
//                 },
//                 time: {
//                     feature: Feature.Time,
//                     requiresRole: Role.Manager,
//                     title: "Zeiterfassung",
//                     description:
//                         "Der Mitarbeiter hat Zugriff auf den Bereich Zeiterfassung " +
//                         "und kann dort erfasste Arbeitszeiten einsehen. Desweiteren kann er " +
//                         "das Zeiterfassungsystem (Pentatime) auf Endgeräten einrichten und verwalten.",
//                 },
//                 revenues: {
//                     feature: Feature.Revenues,
//                     requiresRole: Role.Manager,
//                     title: "Umsätze",
//                     children: {
//                         daily: { title: "Tagesabrechnung" },
//                         cashbook: { title: "Kassenbuch" },
//                         debtors: { title: "Debitoren" },
//                         groups: { title: "Umsatzgruppen" },
//                     },
//                 },
//                 planning: {
//                     feature: Feature.Planning,
//                     requiresRole: Role.Manager,
//                     title: "Planung",
//                     description:
//                         "Der Mitarbeiter hat Zugriff auf den Bereich Planung und kann dort " +
//                         "Planumsätze und Stundenvorgaben definieren.",
//                     children: {
//                         revenue: { title: "Umsätze" },
//                         schedule: { title: "Stundenvorgaben" },
//                     },
//                 },
//                 reports: {
//                     requiresRole: Role.Manager,
//                     title: "Berichte",
//                     description:
//                         "Der Mitarbeiter hat Zugriff auf den Bereich Berichte und kann dort " +
//                         "verschiedene Kennzahlen und Analysen einsehen.",
//                     children: {
//                         savings: { title: "Ersparnis" },
//                         costs: { title: "Personalkosten" },
//                         time: { title: "Arbeitszeit" },
//                         revenue: { title: "Umsätze" },
//                     },
//                 },
//                 issues: {
//                     requiresRole: Role.Manager,
//                     title: "Probleme",
//                     description:
//                         "Der Mitarbeiter hat Zugriff auf den Bereich Probleme und kann dort " +
//                         "Arbeitszeit- und Vertragsbezogene Probleme einsehen, beheben und verwerfen.",
//                 },
//                 recruiting: {
//                     requiresRole: Role.Manager,
//                     title: "Recruiting",
//                     description:
//                         "Der Mitarbeiter hat Zugriff auf den Bereich Recruiting und kann dort " +
//                         "Stellenausschreibungen bearbeiten und Bewerbungen einsehen und beantworten.",
//                 },
//                 exports: {
//                     requiresRole: Role.Manager,
//                     title: "Datenexport",
//                     description:
//                         'Der Mitarbeiter hat Zugriff auf den Bereich "Datenexport" und kann dort ' +
//                         "verschiedene Bewegungs- und Stammdaten exportieren.",
//                     children: {
//                         payroll: { title: "Lohnbuchhaltung" },
//                         time: { title: "Arbeitszeiten" },
//                         employee_data: { title: "Stammdaten" },
//                         cashbook: { title: "Kassenbuch" },
//                         ledgers: { title: "Konten" },
//                     },
//                 },
//             },
//         },
//         employees: {
//             title: "Mitarbeiter-App",
//             children: {
//                 schedule: {
//                     title: "Dienstplan",
//                     description: "Der Mitarbeiter kann seinen persönlichen Dienstplan einsehen",
//                     children: {
//                         take: { title: "Schichten Übernehmen" },
//                         offer: { title: "Zum Tausch Freigeben" },
//                     },
//                 },
//                 absences: {
//                     title: "Abwesenheiten",
//                     description:
//                         "Der Mitarbeiter kann seine eigenen Abwesenheitszeiträume einsehen, Urlaubsanträge stellen und Krankmeldungen einreichen.",
//                     children: {
//                         requestVacation: { title: "Urlaub Beantragen" },
//                         requestSick: { title: "Krank Melden" },
//                     },
//                 },
//                 availabilities: {
//                     title: "Verfügbarkeiten",
//                     description: "Der Mitarbeiter kann über die Mitarbeiter-App Verfügbarkeiten eintragen.",
//                 },
//                 time: {
//                     title: "Arbeitszeiten",
//                     description: "Der Mitarbeiter kann seine eigenen Arbeitszeiten einsehen.",
//                 },
//                 ledgers: {
//                     title: "Konten",
//                     description: "Der Mitarbeiter kann seine eigenen Konten einsehen.",
//                 },
//                 tracking: {
//                     title: "Stempeluhr",
//                     description: "Der Mitarbeiter kann über die Mitarbeiter-App Zeiten stempeln.",
//                 },
//                 documents: {
//                     title: "Dokumente",
//                     description: "Der Mitarbeiter kann seine eigenen Dokumente einsehen.",
//                 },
//             },
//         },
//     },
// } as const;

export type PermissionInfo = Immutable<{
    key: string;
    title: string;
    feature?: Feature;
    requiresRole?: Role;
    description?: string;
    children?: PermissionInfo[];
    color?: string;
    requires?: string[];
    grid?: boolean;
    readonly?: boolean;
    hide?: boolean;
    icon?: string;
}>;

export const PERMISSIONS = {
    key: "",
    title: "Permissions",
    children: [
        {
            key: "manage.roster",
            feature: Feature.Roster,
            requiresRole: Role.Manager,
            title: "Dienstplan",
            description: "Der Mitarbeiter kann für bestimmte Abteilungen Dienstpläne schreiben und veröffentlichen.",
            grid: true,
            icon: "table",
            children: [
                {
                    title: "Einsehen & Bearbeiten",
                    key: "manage.roster",
                    icon: "pencil",
                    readonly: true,
                    requires: [`manage.roster`],
                },
                {
                    title: "Veröffentlichen",
                    key: "manage.roster.publish",
                    requires: ["manage.roster"],
                    icon: "paper-plane",
                },
                {
                    title: "Kosten Einsehen",
                    key: "manage.roster.costs",
                    requires: ["manage.roster"],
                    icon: "euro-sign",
                },
                {
                    title: "Notizen Verwalten",
                    key: "manage.roster.notes",
                    requires: ["manage.roster"],
                    icon: "sticky-note",
                },
            ],
        },
        {
            key: "manage.employees",
            feature: Feature.Employees,
            requiresRole: Role.Manager,
            title: "Mitarbeiter",
            description:
                "Der Mitarbeiter hat Zugriff auf den Bereich Mitarbeiter und kann dort Stammdaten, Arbeitsbereiche und Berechtigungen einsehen und bearbeiten.",
            grid: true,
            icon: "people-group",
            children: [
                {
                    key: "manage.employees.time",
                    title: "Arbeitszeiten",
                    icon: "hourglass-clock",
                },
                {
                    key: "manage.employees.absences",
                    title: "Abwesenheiten",
                    icon: "person-to-portal",
                },
                {
                    key: "manage.employees.availabilities",
                    title: "Verfügbarkeiten",
                    icon: "comment-check",
                },
                {
                    key: "manage.employees.documents",
                    title: "Dokumente",
                    icon: "files",
                },
                {
                    key: "manage.employees.payroll",
                    title: "Löhne",
                    icon: "sack-dollar",
                },
                {
                    key: "manage.employees.advances",
                    title: "Vorschüsse",
                    icon: "hand-holding-dollar",
                },
                {
                    key: "manage.employees.ledgers",
                    title: "Konten",
                    icon: "scale-unbalanced",
                },
                {
                    key: "manage.employees.core_data",
                    title: "Stammdaten",
                    icon: "address-card",
                },
                {
                    key: "manage.employees.contract",
                    title: "Verträge",
                    icon: "file-contract",
                },
                {
                    key: "manage.employees.permissions",
                    title: "Zugänge & Rechte",
                    icon: "user-lock",
                },
                {
                    key: "manage.employees.positions",
                    title: "Positionen",
                    icon: "arrows-down-to-people",
                },
                {
                    key: "manage.employees.create",
                    title: "Mitarbeiter Anlegen",
                    icon: "person-circle-plus",
                },
                {
                    key: "manage.employees.delete",
                    title: "Mitarbeiter Löschen",
                    icon: "person-circle-minus",
                },
                {
                    key: "manage.employees.messages",
                    title: "Nachrichten Versenden",
                    icon: "message-lines",
                },
            ],
        },
        {
            key: "manage.settings",
            requiresRole: Role.Manager,
            title: "Einstellungen",
            description: "Der Mitarbeiter kann Änderungen an den Grundeinstellungen vornehmen.",
            grid: true,
            icon: "cog",
            children: [
                {
                    key: "manage.settings.company",
                    title: "Unternehmen",
                    icon: "building",
                },
                {
                    key: "manage.settings.venues",
                    title: "Arbeitsbereiche",
                    icon: "sitemap",
                },
                {
                    key: "manage.settings.time",
                    title: "Zeiterfassung",
                    icon: "stopwatch",
                },
                {
                    key: "manage.settings.core_data",
                    title: "Stammdaten",
                    icon: "address-card",
                },
                {
                    key: "manage.settings.documents",
                    title: "Dokumente",
                    icon: "files",
                },
                {
                    key: "manage.settings.bonuses",
                    title: "Zuschläge",
                    icon: "badge-percent",
                },
                {
                    key: "manage.settings.benefits",
                    title: "Sonderleistungen",
                    icon: "hand-holding-box",
                },
                {
                    key: "manage.settings.accounting",
                    title: "Buchhaltung",
                    icon: "abacus",
                },
                {
                    key: "manage.settings.billing",
                    title: "Rechnungsstellung",
                    icon: "file-invoice-dollar",
                },
            ],
        },
        {
            key: "manage.time",
            feature: Feature.Time,
            requiresRole: Role.Manager,
            title: "Zeiterfassung",
            grid: true,
            icon: "stopwatch",
            description:
                "Der Mitarbeiter hat Zugriff auf den Bereich Zeiterfassung " +
                "und kann dort erfasste Arbeitszeiten einsehen. Desweiteren kann er " +
                "das Zeiterfassungsystem (Pentatime) auf Endgeräten einrichten und verwalten.",

            children: [
                {
                    title: "Einsehen & Bearbeiten",
                    key: "manage.time",
                    readonly: true,
                    requires: ["staff.roster"],
                    icon: "pencil",
                },
                {
                    title: "Protokoll Einsehen",
                    key: "manage.time.events",
                    requires: ["manage.time"],
                    icon: "timeline-arrow",
                },
            ],
        },
        {
            key: "manage.revenues",
            feature: Feature.Revenues,
            requiresRole: Role.Manager,
            title: "Umsätze",
            icon: "euro-sign",
            grid: true,
            children: [
                { icon: "cash-register", key: `manage.revenues.daily`, title: "Tagesabrechnung" },
                { icon: "book", key: `manage.revenues.cashbook`, title: "Kassenbuch" },
                { icon: "file-invoice-dollar", key: `manage.revenues.debtors`, title: "Debitoren" },
                { icon: "filter-circle-dollar", key: `manage.revenues.groups`, title: "Umsatzgruppen" },
            ],
        },
        {
            key: "manage.planning",
            feature: Feature.Planning,
            requiresRole: Role.Manager,
            title: "Planung",
            description:
                "Der Mitarbeiter hat Zugriff auf den Bereich Planung und kann dort " +
                "Planumsätze und Stundenvorgaben definieren.",
            grid: true,
            icon: "calendar",
            children: [
                { key: `manage.planning.revenue`, title: "Umsätze", icon: "euro-sign" },
                { key: `manage.planning.roster`, title: "Stundenvorgaben", icon: "bars-progress" },
            ],
        },
        {
            key: "manage.reports",
            requiresRole: Role.Manager,
            title: "Berichte",
            description:
                "Der Mitarbeiter hat Zugriff auf den Bereich Berichte und kann dort " +
                "verschiedene Kennzahlen und Analysen einsehen.",
            grid: true,
            icon: "chart-pie",
            children: [
                { key: `manage.reports.savings`, title: "Ersparnis", icon: "piggy-bank" },
                { key: `manage.reports.costs`, title: "Personalkosten", icon: "hand-holding-dollar" },
                { key: `manage.reports.time`, title: "Arbeitszeit", icon: "hourglass-clock" },
                { key: `manage.reports.revenue`, title: "Umsätze", icon: "sack-dollar" },
            ],
        },
        {
            key: "manage.issues",
            requiresRole: Role.Manager,
            title: "Probleme",
            icon: "exclamation-triangle",
            description:
                "Der Mitarbeiter hat Zugriff auf den Bereich Probleme und kann dort " +
                "Arbeitszeit- und Vertragsbezogene Probleme einsehen, beheben und verwerfen.",
        },
        {
            key: "manage.recruiting",
            requiresRole: Role.Manager,
            title: "Recruiting",
            icon: "image-polaroid-user",
            description:
                "Der Mitarbeiter hat Zugriff auf den Bereich Recruiting und kann dort " +
                "Stellenausschreibungen bearbeiten und Bewerbungen einsehen und beantworten.",
        },
        {
            key: "manage.exports",
            requiresRole: Role.Manager,
            title: "Datenexport",
            description:
                'Der Mitarbeiter hat Zugriff auf den Bereich "Datenexport" und kann dort ' +
                "verschiedene Bewegungs- und Stammdaten exportieren.",
            grid: true,
            icon: "download",
            children: [
                {
                    key: "manage.exports.payroll",
                    title: "Lohnbuchhaltung",
                    icon: "hand-holding-dollar",
                },
                {
                    key: "manage.exports.time",
                    title: "Arbeitszeiten",
                    icon: "list-timeline",
                },
                {
                    key: "manage.exports.roster",
                    title: "Dienstplan",
                    icon: "table",
                },
                {
                    key: "manage.exports.employee_data",
                    title: "Stammdaten",
                    icon: "address-card",
                },
                {
                    key: "manage.exports.cashbook",
                    title: "Kassenbuch",
                    icon: "book",
                },
                {
                    key: "manage.exports.ledgers",
                    title: "Konten",
                    icon: "scale-unbalanced",
                },
            ],
        },
        {
            key: "staff.roster",
            title: "Dienstplan",
            description: "Der Mitarbeiter kann seinen persönlichen Dienstplan einsehen",
            grid: true,
            icon: "table",
            children: [
                {
                    title: "Einsehen",
                    key: "staff.roster",
                    readonly: true,
                    requires: ["staff.roster"],
                    icon: "eye",
                },
                {
                    title: "Schichten Übernehmen",
                    key: "staff.roster.take",
                    requires: ["staff.roster"],
                    icon: "hand-back-fist",
                },
                {
                    title: "Zum Tausch Freigeben",
                    key: "staff.roster.offer",
                    requires: ["staff.roster"],
                    icon: "hand-holding-magic",
                },
            ],
        },
        {
            key: "staff.absences",
            title: "Abwesenheiten",
            description:
                "Der Mitarbeiter kann seine eigenen Abwesenheitszeiträume einsehen, Urlaubsanträge stellen und Krankmeldungen einreichen.",
            grid: true,
            icon: "person-to-portal",
            children: [
                {
                    title: "Einsehen",
                    key: "staff.absences",
                    readonly: true,
                    requires: ["staff.absences"],
                    icon: "eye",
                },
                {
                    title: "Urlaubsanträge",
                    key: "staff.absences.request_vacation",
                    requires: ["staff.absences"],
                    icon: "person-praying",
                },
                {
                    title: "Krankmeldungen",
                    key: "staff.absences.request_sick",
                    requires: ["staff.absences"],
                    icon: "face-vomit",
                },
            ],
        },
        {
            key: "staff.availabilities",
            title: "Verfügbarkeiten",
            description: "Der Mitarbeiter kann über die Mitarbeiter-App Verfügbarkeiten eintragen.",
            icon: "comment-check",
        },
        {
            key: "staff.time",
            title: "Arbeitszeiten",
            description: "Der Mitarbeiter kann seine eigenen Arbeitszeiten einsehen.",
            icon: "hourglass-clock",
        },
        {
            key: "staff.ledgers",
            title: "Konten",
            description: "Der Mitarbeiter kann seine eigenen Konten einsehen.",
            icon: "scale-unbalanced",
        },
        {
            key: "staff.tracking",
            title: "Stempeluhr",
            description: "Der Mitarbeiter kann über die Mitarbeiter-App Zeiten stempeln.",
            icon: "stopwatch",
        },
        {
            key: "staff.documents",
            title: "Dokumente",
            description: "Der Mitarbeiter kann seine eigenen Dokumente einsehen.",
            icon: "files",
        },
    ],
} as const;

type Values<Obj> = Obj[keyof Obj] | never;

// type ScopesFromDefinition<Def extends ScopeDefinition, Path extends string = ""> = Def extends {
//     children: infer Children extends Record<string, ScopeDefinition>;
// }
//     ?
//           | (Path extends "" ? never : Path)
//           | Values<{
//                 [K in keyof Children & string]: ScopesFromDefinition<Children[K], Path extends "" ? K : `${Path}.${K}`>;
//             }>
//     : Path extends ""
//     ? never
//     : Path;

type PermissionsFromInfo<Def extends PermissionInfo> = Def extends {
    children: infer Children extends readonly PermissionInfo[];
}
    ?
          | (Def["key"] extends "" ? never : Def["key"])
          | Values<{
                [C in Children[number] as C["key"]]: PermissionsFromInfo<C>;
            }>
    : Def["key"] extends ""
      ? never
      : Def["key"];

export type Permission = PermissionsFromInfo<typeof PERMISSIONS>;

function recursiveGetPermissions(scopeDef: PermissionInfo) {
    const scopes: string[] = scopeDef.key ? [scopeDef.key] : [];

    if (scopeDef.children) {
        for (const scope of scopeDef.children) {
            scopes.push(...recursiveGetPermissions(scope));
        }
    }

    return scopes;
}

export const ALL_PERMISSIONS = recursiveGetPermissions(PERMISSIONS) as Permission[];
