import { minute } from "@pentacode/core/src/hours";
import { version, VersionInfo } from "@pentacode/core/src/version";
import { Workbox, messageSW } from "workbox-window";
import { getSingleton } from "../lib/singleton";
import { UpdateNotification } from "../elements/update-notification";
import "../elements/update-notification";

type Constructor<T> = new (...args: any[]) => T;

export function ServiceWorker<B extends Constructor<object>>(baseClass: B) {
    return class extends baseClass {
        constructor(...args: any[]) {
            super(...args);
            this.initSW();
        }

        private _wb: Workbox;

        private _pollUpdateTimeout: any;

        private async _updateReady(info: VersionInfo) {
            // const confirmed = await (getSingleton("ptc-update-dialog") as UpdateDialog).show(info);

            // if (confirmed) {
            // set up a listener that will reload the page as soon as the
            // previously waiting service worker has taken control.
            this._wb.addEventListener("controlling", () => {
                (getSingleton("ptc-update-notification") as UpdateNotification).show(info);
            });

            // Send a message telling the service worker to skip waiting.
            // This will trigger the `controlling` event handler above.
            this._wb.messageSW({ type: "INSTALL_UPDATE" });
            // }
        }

        initSW() {
            if (!("serviceWorker" in navigator)) {
                return;
            }

            this._wb = new Workbox("/sw.js");

            // Add an event listener to detect when the registered
            // service worker has installed but is waiting to activate.
            this._wb.addEventListener("waiting", async (e: any) => {
                const info = (await messageSW(e.sw, { type: "GET_VERSION", currentVersion: version })) as VersionInfo;
                this._updateReady(info);
            });

            this._wb.register();

            this.pollForUpdates();
        }

        async checkForUpdates() {
            try {
                this._wb.update();
            } catch (e) {}
        }

        pollForUpdates(interval = 10 * minute) {
            clearTimeout(this._pollUpdateTimeout);
            this.checkForUpdates();
            this._pollUpdateTimeout = setTimeout(() => this.pollForUpdates(interval), interval);
        }
    };
}
