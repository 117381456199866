import { classToPlain, plainToClass, classToClass, Transform } from "class-transformer";
import { validateSync } from "class-validator";
import { Err, ErrorCode } from "./error";

type ClassType<T> = {
    new (...args: any[]): T;
};

export function serialize<T>(cl: T | T[]): any {
    return classToPlain(cl);
}

export { Type, Exclude } from "class-transformer";

export function deserialize<T>(cl: ClassType<T>, obj: any): T {
    if (Array.isArray(obj)) {
        throw new Err(ErrorCode.ENCODING_ERROR, "Deserializing arrays not supported");
    }

    const inst = plainToClass(cl, obj);
    return inst as any as T;
}

export function deserializeArray<T>(cl: ClassType<T>, obj: any): T[] {
    if (!Array.isArray(obj)) {
        throw new Err(ErrorCode.ENCODING_ERROR, "Object must be an array!");
    }

    const inst = plainToClass(cl, obj);
    const errors = validateSync(inst);
    if (errors.length) {
        throw new Err(ErrorCode.ENCODING_ERROR, Object.values(errors[0].constraints).join(", "));
    }
    return inst as any as T[];
}

export function clone<T>(cl: T): T {
    return classToClass(cl);
}

export const validate = validateSync;

export interface Marshaler {
    mimeType: string;
    marshal(inp: any): string;
    unmarshal(inp: string): any;
}

export class JSONMarshaler implements Marshaler {
    mimeType = "application/json";

    marshal(input: any) {
        try {
            return JSON.stringify(input);
        } catch (e) {
            throw new Err(ErrorCode.ENCODING_ERROR, e.message);
        }
    }

    unmarshal(input: string) {
        try {
            return JSON.parse(input);
        } catch (e) {
            throw new Err(ErrorCode.ENCODING_ERROR, e.message);
        }
    }
}

export function bytesToHex(bytes: Uint8Array) {
    return [...bytes].map((b) => b.toString(16).padStart(2, "0")).join("");
}

export function hexToBytes(str: string): Uint8Array {
    const bytes = new Uint8Array(str.length / 2);
    for (let i = 0; i < bytes.length; i++) {
        bytes[i] = parseInt(str.substring(i * 2, i * 2 + 2), 16);
    }
    return bytes;
}

export function intToBytes(x: number) {
    const y = Math.floor(x / 2 ** 32);
    return new Uint8Array([y, y << 8, y << 16, y << 24, x, x << 8, x << 16, x << 24].map((z) => z >>> 24));
}

export function bytesToInt(byteArr: Uint8Array) {
    return byteArr.reduce((a, c, i) => a + c * 2 ** (56 - i * 8), 0);
}

/**
 * Converts a utf-8 string to a byte array
 */
export function stringToBytes(str: string): Uint8Array {
    try {
        return new TextEncoder().encode(str);
    } catch (e) {
        throw new Err(ErrorCode.ENCODING_ERROR, e.toString());
    }
}

/**
 * Converts a byte array to an utf-8 string
 */
export function bytesToString(bytes: Uint8Array, encoding = "utf-8") {
    try {
        return new TextDecoder(encoding).decode(bytes);
    } catch (e) {
        throw new Err(ErrorCode.ENCODING_ERROR, e.toString());
    }
}

const base32Chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ234567";

export function bytesToBase32(arr: Uint8Array) {
    let bits = 0;
    let value = 0;
    let str = "";

    for (let i = 0; i < arr.length; i++) {
        value = (value << 8) | arr[i];
        bits += 8;

        while (bits >= 5) {
            str += base32Chars[(value >>> (bits - 5)) & 31];
            bits -= 5;
        }
    }

    if (bits > 0) {
        str += base32Chars[(value << (5 - bits)) & 31];
    }

    return str;
}

export function base32ToBytes(str: string) {
    const strUpp = str.toUpperCase();
    const arr = new Uint8Array(((str.length * 5) / 8) | 0);

    let bits = 0;
    let value = 0;
    let index = 0;

    for (let i = 0; i < strUpp.length; i++) {
        const idx = base32Chars.indexOf(strUpp[i]);

        if (idx === -1) {
            throw new Err(ErrorCode.ENCODING_ERROR, `Invalid Base32 character found: ${strUpp[i]}`);
        }

        value = (value << 5) | idx;
        bits += 5;

        if (bits >= 8) {
            arr[index++] = (value >>> (bits - 8)) & 255;
            bits -= 8;
        }
    }

    return arr;
}

export function TransformDateProperties(keys: readonly string[]): PropertyDecorator {
    return Transform(
        (value: any) => {
            if (!value || typeof value !== "object") {
                return value;
            }

            for (const key of keys) {
                if (value[key]) {
                    value[key] = new Date(value[key]);
                }
            }
            return value;
        },
        { toClassOnly: true }
    );
}
