import { formatDate, monthNames } from "./util";
import { BillingMode } from "./model";
import Stripe from "stripe";

export enum InvoiceStatus {
    Open = "open",
    Paid = "paid",
    Refunded = "refunded",
    Disputed = "disputed",
    Void = "void",
    Uncollectible = "uncollectible",
    Draft = "draft",
}

export class Invoice {
    raw: Stripe.Invoice;

    creditNotes: Stripe.CreditNote[] = [];

    constructor(raw?: Stripe.Invoice, products: Stripe.Product[] = [], creditNotes: Stripe.CreditNote[] = []) {
        if (raw) {
            this.raw = raw;
            // We can't expand the product field in the invoice line items as part of the api request,
            // so we fetch products separately and assign them to the line items by matching the product id
            this.raw.lines.data.forEach(
                (item: any) => (item.price.product = products.find((p) => p.id === item.price.product))
            );
        }

        this.creditNotes = creditNotes.filter((c) => c.invoice === this.raw.id);
    }

    get id() {
        return this.raw.id;
    }

    get created() {
        return new Date(this.raw.created * 1000);
    }

    get number() {
        return this.raw.number;
    }

    get billingDate() {
        return this.raw.metadata?.billing_date ? new Date(this.raw.metadata.billing_date) : this.finalized;
    }

    get dueDate() {
        return (this.raw.due_date && new Date(this.raw.due_date * 1000)) || this.period.start;
    }

    get finalized() {
        const ts = this.raw.status_transitions.finalized_at;
        return (ts && new Date(ts * 1000)) || null;
    }

    get paid() {
        const ts = this.raw.status_transitions.paid_at;
        return (ts && new Date(ts * 1000)) || null;
    }

    get period() {
        const firstItem = this.raw.lines.data[0];
        return {
            start: new Date(firstItem.period.start * 1000),
            end: new Date(firstItem.period.end * 1000),
        };
    }

    get total() {
        return this.raw.total / 100;
    }

    get totalNet() {
        return this.total - this.totalTax;
    }

    get totalTax() {
        return this.taxes.reduce((total: number, { amount }: { amount: number }) => total + amount, 0);
    }

    get adjustedTotal() {
        return this.total + this.creditTotal;
    }

    get amountDue() {
        return this.raw.amount_due / 100 + this.creditTotal;
    }

    get creditSubTotal() {
        return this.creditItems.reduce((total: number, { amount }: { amount: number }) => total + amount, 0);
    }

    get creditTotal() {
        return (
            this.creditSubTotal +
            this.creditDiscounts.reduce((total: number, { amount }: { amount: number }) => total + amount, 0) +
            this.creditTaxes.reduce((total: number, { amount }: { amount: number }) => total + amount, 0)
        );
    }

    get subtotal() {
        return this.items.reduce((total: number, { amount }: { amount: number }) => total + amount, 0);
    }

    get items() {
        return this.raw.lines.data.map(
            (
                item: Stripe.InvoiceLineItem & {
                    price: Stripe.Price & {
                        product: Stripe.Product;
                        unit_amount: number;
                    };
                    tax_rates: Stripe.TaxRate[];
                    tax_amounts: Stripe.InvoiceLineItem.TaxAmount[];
                    discounts: string[];
                }
            ) => {
                const product = item.price.product;
                let period = "";
                const startDate = new Date(item.period.start * 1000);
                const endDate = new Date(item.period.end * 1000);

                switch (product?.metadata.period) {
                    case "previousMonth":
                        startDate.setDate(startDate.getDate() - 2);
                        period = `${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}`;
                        break;
                    case "currentMonth":
                        startDate.setDate(startDate.getDate() + 2);
                        period = `${monthNames[startDate.getMonth()]} ${startDate.getFullYear()}`;
                        break;
                    default:
                        period = `${formatDate(startDate)} - ${formatDate(endDate)}`;
                        break;
                }

                const tax = item.tax_amounts[0];
                const taxRate =
                    (item.tax_rates[0] && item.tax_rates[0]) ||
                    (tax &&
                        this.raw.default_tax_rates &&
                        this.raw.default_tax_rates.find((rate) => rate.id === tax.tax_rate));

                const baseAmount = item.amount / 100;
                const discounts =
                    item.discount_amounts
                        ?.filter((d) => item.discounts.includes(d.discount as string))
                        .map((d) => {
                            const discount = this.raw.total_discount_amounts?.find(
                                (d) => (d.discount as Stripe.Discount).invoice_item === item.invoice_item
                            )?.discount as Stripe.Discount;
                            return {
                                description: discount?.coupon?.name,
                                amount: -d.amount / 100,
                                priceAmountOff: discount?.coupon?.amount_off,
                                pricePercentOff: discount?.coupon?.percent_off,
                            };
                        }) || [];

                const basePrice = item.price.unit_amount / 100;
                const price =
                    basePrice +
                    discounts.reduce((total, { priceAmountOff, pricePercentOff }) => {
                        let res = total;
                        if (priceAmountOff) {
                            res -= priceAmountOff / 100;
                        }
                        if (pricePercentOff) {
                            res -= (basePrice * pricePercentOff) / 100;
                        }
                        return res;
                    }, 0);

                return {
                    id: item.invoice_item,
                    baseAmount,
                    basePrice,
                    price,
                    quantity: item.quantity,
                    description: (product && product.description) || item.description,
                    taxRate: (taxRate && taxRate.percentage) || 0,
                    taxAmount: (tax && tax.amount / 100) || 0,
                    period,
                    productId: product?.id,
                    discounts,
                    amount: baseAmount + discounts.reduce((total, { amount }) => total + amount, 0),
                };
            }
        );
    }

    get creditItems() {
        return this.creditNotes
            .filter((n) => n.status !== "void")
            .flatMap((c) =>
                c.lines.data.map((item) => {
                    const tax = item.tax_amounts[0];
                    const taxRate =
                        (item.tax_rates[0] && item.tax_rates[0]) ||
                        (tax &&
                            this.raw.default_tax_rates &&
                            this.raw.default_tax_rates.find((rate) => rate.id === tax.tax_rate));

                    const baseAmount = -item.amount / 100;
                    const discounts = (item.discount_amounts
                        .map((disc) => {
                            const discount = this.raw.total_discount_amounts?.find(
                                (d) => (d.discount as Stripe.Discount).id === (disc.discount as string)
                            )?.discount as Stripe.Discount;
                            return (
                                (!!discount.invoice_item && {
                                    description: discount?.coupon?.name,
                                    amount: disc.amount / 100,
                                }) ||
                                null
                            );
                        })
                        .filter(Boolean) || []) as { amount: number; description: string }[];
                    return {
                        baseAmount,
                        price: item.unit_amount! / 100,
                        quantity: item.quantity,
                        description: item.description,
                        taxRate: (taxRate && taxRate.percentage) || 0,
                        taxAmount: (tax && -tax.amount / 100) || 0,
                        period: undefined,
                        discounts,
                        amount: baseAmount + discounts.reduce((total, { amount }) => total + amount, 0),
                    };
                })
            );
    }

    get taxes() {
        const rates = new Map<number, number>();

        for (const item of this.items) {
            if (!rates.has(item.taxRate)) {
                rates.set(item.taxRate, 0);
            }

            rates.set(item.taxRate, rates.get(item.taxRate)! + item.taxAmount);
        }

        return [...rates.entries()].filter(([, amount]) => !!amount).map(([rate, amount]) => ({ rate, amount }));
    }

    get creditTaxes() {
        const rates = new Map<number, number>();

        for (const item of this.creditItems) {
            if (!rates.has(item.taxRate)) {
                rates.set(item.taxRate, 0);
            }

            rates.set(item.taxRate, rates.get(item.taxRate)! + item.taxAmount);
        }

        return [...rates.entries()].filter(([, amount]) => !!amount).map(([rate, amount]) => ({ rate, amount }));
    }

    get refund() {
        const charge = this.raw.charge as Stripe.Charge;
        const refund = charge && charge.refunds && charge.refunds.data.find((r) => r.status === "succeeded");
        return refund
            ? {
                  date: new Date(refund.created * 1000),
                  amount: refund.amount / 100,
              }
            : null;
    }

    get metadata() {
        return this.raw.metadata || {};
    }

    get billingMode() {
        const customer = this.customer;
        return (customer.metadata?.billingMode as BillingMode) || BillingMode.Default;
    }

    get hogastInfo() {
        const customer = this.customer;
        return this.billingMode === BillingMode.Hogast
            ? {
                  email: customer.metadata.hogastUnionEmail,
                  name: customer.metadata.hogastUnionName,
                  addressLine1: customer.metadata.hogastUnionAddress,
                  addressLine2: `GLN: ${customer.metadata.hogastUnionGln}`,
                  postalCode: customer.metadata.hogastUnionPostalCode,
                  city: customer.metadata.hogastUnionCity,
                  country: customer.metadata.hogastUnionCountry,
                  taxId: customer.metadata.hogastUnionVatId,
                  gln: customer.metadata.hogastUnionGln,
              }
            : null;
    }

    get customer() {
        return this.raw.customer as Stripe.Customer;
    }

    get customerInfo() {
        const customer = this.customer;
        return {
            name: this.raw.customer_name || customer.name,
            email: this.raw.customer_email || customer.email,
            phone: this.raw.customer_phone || customer.phone,
            addressLine1: this.raw.customer_address?.line1 || customer.address?.line1,
            addressLine2: this.raw.customer_address?.line2 || customer.address?.line2,
            postalCode: this.raw.customer_address?.postal_code || customer.address?.postal_code,
            city: (this.raw.customer_address && this.raw.customer_address.city) || customer.address?.city,
            country: customer.address?.country,
            taxId: this.raw.customer_tax_ids?.[0]?.value || customer.tax_ids?.data[0]?.value,
            companyId: customer.metadata?.companyId,
        };
    }

    get billingInfo() {
        return this.billingMode === BillingMode.Hogast && this.hogastInfo ? this.hogastInfo : this.customerInfo;
    }

    get shippingInfo() {
        const customer = this.customer;
        return this.billingMode === BillingMode.Hogast
            ? {
                  ...this.customerInfo,
                  addressLine2: `HOGAST Mitgliedsnummer: ${customer.metadata.hogastMemberId}`,
              }
            : this.raw.customer_shipping
              ? {
                    name: this.raw.customer_shipping.name,
                    addressLine1: this.raw.customer_shipping.address?.line1,
                    addressLine2: this.raw.customer_shipping.address?.line2,
                    postalCode: this.raw.customer_shipping.address?.postal_code,
                    city: this.raw.customer_shipping.address?.city,
                    country: this.raw.customer_shipping.address?.country,
                }
              : customer.shipping
                ? {
                      name: customer.shipping.name,
                      addressLine1: customer.shipping.address?.line1,
                      addressLine2: customer.shipping.address?.line2,
                      postalCode: customer.shipping.address?.postal_code,
                      city: customer.shipping.address?.city,
                      country: customer.shipping.address?.country,
                  }
                : null;
    }

    get receipt() {
        return this.raw.receipt_number;
    }

    get status() {
        switch (this.raw.status) {
            case "paid": {
                const charge = this.raw.charge as Stripe.Charge;
                if (charge && charge.refunded) {
                    return InvoiceStatus.Refunded;
                } else if (charge && charge.disputed) {
                    return InvoiceStatus.Disputed;
                }
                return this.raw.status as InvoiceStatus;
            }
            default:
                return this.raw.status as InvoiceStatus;
        }
    }

    get invoiceDiscounts(): { description: string; amount: number }[] {
        return (
            this.raw.total_discount_amounts
                ?.filter((d) => this.raw.discounts?.includes((d.discount as Stripe.Discount).id))
                .map(({ discount, amount }: { discount: Stripe.Discount; amount: number }) => {
                    const description =
                        discount.coupon?.name ||
                        `Preisnachlass ${discount.coupon?.percent_off ? `(${discount.coupon.percent_off}%)` : `(${discount.coupon.amount_off}€)`}`;

                    return {
                        description,
                        amount: -amount / 100,
                    };
                }) || []
        );
    }

    get creditDiscounts() {
        return this.creditNotes.flatMap((note) =>
            note.discount_amounts
                ?.filter((d) => !(d.discount as Stripe.Discount).invoice_item)
                .map(({ discount, amount }: { discount: Stripe.Discount; amount: number }) => ({
                    description: discount.coupon?.name,
                    amount: amount / 100,
                }))
        );
    }

    get pdf() {
        return this.raw.metadata?.pdf || null;
    }

    set pdf(value: string | null) {
        this.raw.metadata = {
            ...this.raw.metadata,
            pdf: value || "",
        };
    }

    get hogastXmlInvoice() {
        return this.raw.metadata?.hogastXmlInvoice || null;
    }

    set hogastXmlInvoice(value: string | null) {
        this.raw.metadata = {
            ...this.raw.metadata,
            hogastXmlInvoice: value || "",
        };
    }

    get hogastXmlDeliveryNote() {
        return this.raw.metadata?.hogastXmlDeliveryNote || null;
    }

    set hogastXmlDeliveryNote(value: string | null) {
        this.raw.metadata = {
            ...this.raw.metadata,
            hogastXmlDeliveryNote: value || "",
        };
    }

    get statusIcon() {
        switch (this.status) {
            case InvoiceStatus.Draft:
                return "grey colored-text spinner";
            case InvoiceStatus.Open:
                return "orange colored-text clock";
            case InvoiceStatus.Paid:
                return "green colored-text check";
            case InvoiceStatus.Refunded:
                return "undo";
            case InvoiceStatus.Uncollectible:
            case InvoiceStatus.Disputed:
                return "red colored-text times";
            default:
                return "question";
        }
    }
}
